import React from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { ForeignerTradingContext, initialForeignerTradingStore } from './store';
import ForeignerTradingContainer from './container';
import History from './history';
import UserACL from '../../../../../../components/UserACL';

const ForeignerTrading = (props) => {
    let { glContainer } = props;
    const { t } = useTranslation();

    return (
        <UserACL feature="foreigner_trading">
            <div className="plugin-wrapper">
                <Tabs defaultActiveKey="history" size="small" animated={false} className="p-1">
                    <Tabs.TabPane tab={t('Lịch sử giao dịch')} key="history">
                        <History glContainer={glContainer} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('Giao dịch trong phiên')} key="today">
                        <ForeignerTradingContext.Provider value={initialForeignerTradingStore}>
                            <ForeignerTradingContainer glContainer={glContainer} />
                        </ForeignerTradingContext.Provider>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </UserACL>
    );
};

export default ForeignerTrading;